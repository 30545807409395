import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { CharitableDonationService } from './../services/charitable-donation/charitable-donation.service'
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: 'app-charitable-giving-home',
  templateUrl: './charitable-giving-home.component.html',
  styleUrls: ['./charitable-giving-home.component.scss']
})
export class CharitableGivingHomeComponent implements OnInit {
  progress = true;
  ngoList = [];
  causes = [];
  selectedReviewIndex = 0;
  reviews = [];
  showCauseModal: boolean;
  showPaymentModal: boolean;
  selectedCause;
  selectedNgo;
  filteredCauses;
  filteredNgos;
  payment = {
    amount: 500,
    type: 'once',
    count: 2
  }
  initialId;
  showBanner: boolean = false;

  @ViewChild("bottomContainer", { static: false }) bottomContainer: ElementRef;
  @ViewChild("mobBottomContainer", { static: false }) mobBottomContainer: ElementRef;

  constructor(
    private charitableDonationService: CharitableDonationService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public data: DataService,
  ) { }

  ngOnInit() {
    // id=ngo
    window.scrollTo(0, 0);
    this.setReviews();
    this.changeReview();
    this.getNgoList();
    this.checkHeaderVisibility();
    this.initialId = this.route.snapshot.queryParamMap.get("id");
    this.payment = this.charitableDonationService.payment;
    
  }

  getNgoList() {
    this.charitableDonationService.getNgoList().then((res: any) => {
      this.ngoList = res.ngo_list;
      this.causes = res.causes;
      this.bindCauseToColor();
      this.mapNgoToCause();
      this.mapCauseToNgo();
      this.filterCauses();
      this.progress = false;
      setTimeout(() => {
        if (this.initialId == 'ngo') {
          this.goToBottomContainer();
        }
      }, 100);
    });
  }

  setReviews() {
    this.reviews = [
      { image: 'assets/charity1.png', review: `"If Udaan was not there, I would have never been able to go to school. Today I don’t have to work in other people’s houses"`, by: `Udaan Foundation Beneficiary` },
      { image: 'assets/charity2.png', review: `"When I came to know about my mental disability, I was heartbroken but today when I am representing India at the Special Oympics Winter World Games, I feel I'm no less than anyone."`, by: `Dulfisha, SOS Children's Villages` },
      { image: 'assets/charity3.png', review: `Farhan, fighting a rare blood cancer, was malnourished and underweight. He was put on a monthly ration program that provides monthly groceries for a family of three. Today, he is hungry to win against cancer, and aspires to become "Mumbai ka doctor".`, by: `Cuddles Foundation` },
    ];
  }

  changeReview() {
    setInterval(() => {
      if (this.selectedReviewIndex < this.reviews.length - 1) {
        this.selectedReviewIndex = this.selectedReviewIndex + 1;
      } else {
        this.selectedReviewIndex = 0;
      }
    }, 5000);
  }


  bindCauseToColor() {
    // const colors1 = ['#03224D', '#C30B4C', '#625E00', '#0C9655', '#983600', '#1FC800', '#9100A5', '#682AAB'];
    // const colors2 = ['#E9EFFF', '#FFEEF4', '#FFFFEB', '#ECFFF6', '#FFF4EE', '#E9FFE6', '#FDEEFF', '#F3E8FF']
    const colors1 = ['#252525', '#252525', '#252525', '#252525', '#252525', '#252525', '#252525', '#252525'];
    const colors2 = ['#252525', '#252525', '#252525', '#252525', '#252525', '#252525', '#252525', '#252525'];

    this.causes.forEach((each, index)=>{
      each.color1 = colors1[index % 8];
      each.color2 = colors2[index % 8];
    });
  }

  openCauseModal() {
    this.showCauseModal = true;
    this.addModalOpen();
  }

  closeCauseModal() {
    this.showCauseModal = false;
    this.removeModalOpen();
  }

  openPaymentModal(ngo) {
    this.selectedNgo = ngo;
    this.reset();
    this.showPaymentModal = true;
    this.addModalOpen();
  }

  reset() {
    this.payment.amount = 500;
    this.payment.type = 'once';
    this.payment.count = 2;
  }

  closePaymentModal() {
    this.showPaymentModal = false;
    this.removeModalOpen();
  }


  removeModalOpen() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  addModalOpen() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  mapNgoToCause() {
    this.ngoList.forEach((ngo) => {
      const index = this.causes.findIndex((each) => each.id === ngo.ngo_cause_id);
      if (index > -1) {
        if (this.causes[index].ngo) {
          this.causes[index].ngo.push(ngo);
        } else {
          this.causes[index].ngo = [ngo];
        }
      }
    });
  }

  mapCauseToNgo() {
    this.ngoList.forEach((ngo) => {
      const index = this.causes.findIndex((each) => each.id === ngo.ngo_cause_id);
      if (index > -1) {
        ngo.cause = this.causes[index];
      }
    });
  }

  setCause(cause) {
    this.selectedCause = cause;
    this.filterCauses();
    this.goToBottomContainer();
  }


  filterCauses() {
    if (this.selectedCause && this.selectedCause.id) {
      this.filteredCauses = this.causes.filter((each) => each.id === this.selectedCause.id);
    } else {
      this.filteredCauses = this.causes;
    }
    this.filterNgos();
  }

  filterNgos() {
    if (this.selectedCause && this.selectedCause.id) {
      this.filteredNgos = this.ngoList.filter((each) => each.ngo_cause_id === this.selectedCause.id);
    } else {
      this.filteredNgos = this.ngoList;
    }
  }

  setAmount(amount) {
    this.payment.amount = amount;
  }

  goToBottomContainer() {
    if (this.isMobile()) {
      this.mobBottomContainer.nativeElement.scrollIntoView({ behavior: "smooth" });
    } else {
      this.bottomContainer.nativeElement.scrollIntoView({ behavior: "smooth" });
    }
  }


  isMobile() {
    return window.innerWidth < 576;
  }

  goToCause(eachCause) {
    this.router.navigate(['/charitable-donations/cause/' + eachCause.id]);
  }

  proceed() {
    if (this.payment.amount && this.payment.amount >= 1) {
      this.removeModalOpen();
      this.router.navigate(['/charitable-donations/donation/' + this.selectedNgo.name]);
    } else {
      this.data.triggerToastr('error','Please enter amount.');
    }
  }

  change(type) {
    switch (type) {
      case '+':
        if (this.payment.count < 12) {
          this.payment.count = this.payment.count + 1;
        } else {
          this.data.triggerToastr('error','Cannot be more than 12 months.');
        }
        break;
      case '-':
        if (this.payment.count > 2) {
          this.payment.count = this.payment.count - 1;
        } else {
          this.data.triggerToastr('error','Cannot be less than 2 months.');
        }
        break;
    }
  }

  currencyInputChanged(value) {
    let num = value.replace(/[$,]/g, "");
    num = num.replace(/\D+/g, "");
    return Number(num);
  }

  public checkHeaderVisibility(): void {
    const today = new Date();
    const endDate = new Date('2025-04-02');
  
    this.showBanner = today < endDate;
  }  

}
